import React from 'react';

import splash from './splash.jpg';
import logo from '../../components/Header/dtlogo.png';
import res from '../ServicesPage/res.jpg';
import comm from '../ServicesPage/comm.jpg';
import nat from '../ServicesPage/nat.jpg';
import './HomePage.css';

function HomePage() {
  return (
    <>
      <section className="home container">
        <div className="left">
          <div className="headline">
            <h2>O.C.D CLEANING</h2>
            <img src={logo} alt="O.C.D Cleaning Services logo " />
          </div>
          <p>Organizing, Cleaning, Detailing</p>
        </div>
        <div className="right">
          <img
            src={splash}
            alt="O.C.D Cleaning Services, Lousiana home cleaning"
          />
        </div>
      </section>
      <section className="services container">
        <h2 className="title">WHAT WE OFFER</h2>
        <div className="cards">
          <div className="card">
            <img src={res} alt="Residential Cleaning Services" />
            <h3>Residential</h3>
            <p>
              We clean your home so you can focus on the things that matter.
            </p>
          </div>
          <div className="card">
            <img src={comm} alt="Commercial Cleaning Services" />
            <h3>Commercial</h3>
            <p>Have an office? We clean those too!</p>
          </div>
          <div className="card">
            <img src={nat} alt="Natural Cleaning Services" />
            <h3>Natural Products</h3>
            <p>We offer our clients the best of the best!</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePage;
