import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import logo from './logo.png';
import dtlogo from './dtlogo.png';

import './Header.css';

function Header() {
  const handleMenuClick = () => {
    const toggleBtn = document.querySelector('.toggle');
    const mobileMenu = document.querySelector('.mobile-nav .menu');
    toggleBtn.classList.toggle('menu-open');
    mobileMenu.classList.toggle('show-mobile-nav');
  };

  const hideMenu = () => {
    const toggleBtn = document.querySelector('.toggle');
    const mobileMenu = document.querySelector('.mobile-nav .menu');
    toggleBtn.classList.remove('menu-open');
    mobileMenu.classList.remove('show-mobile-nav');
  };

  window.addEventListener('scroll', () => {
    const logo = document.querySelector('.mobile-nav .logo');
    if (window.scrollY !== 0) {
      logo.classList.add('hide-logo');
    } else {
      logo.classList.remove('hide-logo');
    }
  });

  return (
    <header>
      <nav className="mobile-nav">
        <div className="outer-toggle">
          <div className="toggle" onClick={handleMenuClick}></div>
        </div>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <ul className="menu">
          <li>
            <Link to="/" onClick={hideMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/our-story" onClick={hideMenu}>
              Our Story
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={hideMenu}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={hideMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>

      <nav className="desktop-nav">
        <div className="logo">
          <Link to="/">
            <h1>O.C.D CLEANING SERVICES</h1>
            <img src={dtlogo} alt="" />
          </Link>
        </div>
        <ul className="menu">
          <li>
            <NavLink exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/our-story">Our Story</NavLink>
          </li>
          <li>
            <NavLink to="/services">Services</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
