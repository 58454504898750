import React from 'react';
import left from './about-left.jpg';

import './AboutPage.css';

function AboutPage() {
  return (
    <section className="about container">
      <div className="left">
        <img src={left} alt="" />
      </div>
      <div className="right">
        <h2 className="title">Our Story</h2>
        <p>
          O.C.D started over 10 years ago as a small side job just to bring in
          some extra money. Now we have grown, thanks to our amazing clients,
          from just a local Louisiana business to a national business!
        </p>
        <h2 className="title">Our Customers</h2>
        <div className="reviews">
          <div className="review">
            <div className="stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <p>
              Ashley and her crew are amazing!!! Not only do they clean but
              organize as well! Ashley is super entertaining as well! She's
              funny! I highly recommend O.C.D!!!
            </p>
            <p className="reviewer">Angela H.</p>
          </div>
          <div className="review">
            <div className="stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <p>
              Let me just say that if it has EVER crossed your mind to get some
              help around the house, DON'T HESITATE to give Ashley a call! For
              son long I felt I needed to "do it all". I was tired of feeling
              overwhelmed, and like a failure. Thank you O.C.D Cleaning Services
              for being my life saver!
            </p>
            <p className="reviewer">Lauren W.</p>
          </div>
          <div className="review">
            <div className="stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <p>
              Reasonable price, eager to provide services that may be more
              specific to your individual needs, and a pleasure to do business
              with!
            </p>
            <p className="reviewer">Tracy P.</p>
          </div>
          <div className="review">
            <div className="stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <p>Great business and owner. Would recommend to everyone!</p>
            <p className="reviewer">Stephanie H.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutPage;
