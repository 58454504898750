import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AboutPage from '../pages/AboutPage/AboutPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import HomePage from '../pages/HomePage/HomePage';
import ServicesPage from '../pages/ServicesPage/ServicesPage';

export default () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route path="/our-story" component={AboutPage} />
    <Route path="/services" component={ServicesPage} />
    <Route path="/contact" component={ContactPage} />
  </Switch>
);
