import React, { useEffect, useState } from 'react';
import axios from 'axios';
import contact from './contact.jpg';
import './ContactPage.css';

function ContactPage() {
  const BASE = 'https://ocdllc.herokuapp.com/api/v1/lead';

  const [contactInfo, setContactInfo] = useState({
    name: '',
    phone: '',
    email: '',
    'service-type': 'residential',
    sqft: '',
    location: '',
  });

  const [formSent, setFormSent] = useState(false);

  useEffect(() => {
    setBoxHeight();
  }, []);

  function setBoxHeight() {
    if (window.innerWidth >= 1024) {
      const left = document.querySelector('.left.box');
      const right = document.querySelector('.right');
      const img = document.querySelector('.contact img');

      const boxHeight = right.offsetHeight - 25;
      left.style.height = boxHeight + 'px';
      img.style.height = boxHeight + 100 + 'px';

      console.log(boxHeight);
    }
  }

  function validateInput(input) {
    let res = {
      isValid: false,
      msg: '',
    };

    const numCheck = /^-?\d+\.?\d*$/;
    const phoneCheck = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const emailCheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.value.length <= 0) {
      res.msg = 'All Fields Required';
    } else if (input.name === 'sqft' && !numCheck.test(input.value)) {
      res.msg = 'Square footage must contain only numbers';
    } else if (input.name === 'phone' && !phoneCheck.test(input.value)) {
      res.msg = 'Must enter a valid phone number';
    } else if (input.name === 'email' && !emailCheck.test(input.value)) {
      res.msg = `${input.value} is not a valid email address`;
    } else {
      res.isValid = true;
      res.msg = '';
    }
    return res;
  }

  function validateForm(e) {
    e.preventDefault();
    const inputs = document.querySelectorAll('.form-group input');
    const errorEl = document.querySelector('.error h3');
    const invalidInputs = [];
    let formValid = true;
    for (let i = 0; i < inputs.length; i++) {
      let validityCheck = validateInput(inputs[i]);
      if (!validityCheck.isValid) {
        formValid = false;
        errorEl.innerText = validityCheck.msg;
        inputs[i].classList.add('not-valid');
      } else {
        inputs[i].classList.remove('not-valid');
      }
      invalidInputs[i] = validityCheck.isValid;
    }

    let checker = invalidInputs.every(Boolean);

    if (checker) {
      errorEl.innerText = '';
      if (formValid) {
        console.log(contactInfo);
        try {
          axios.post(`${BASE}`, contactInfo);
          setFormSent(true);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  const handleChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    console.log(e.target.value);
    setContactInfo({ ...contactInfo, 'service-type': e.target.value });
  };

  return (
    <section className="container contact">
      <div className="right">
        <div className={!formSent ? 'success-msg' : 'success-msg show-msg'}>
          <p>
            Someone from our team will reach out with your free quote shortly!
          </p>
        </div>
        <form
          onSubmit={(e) => validateForm(e)}
          className={formSent && 'hide-form'}
        >
          <div className="box">
            <h2>FREE QUOTE?</h2>
            <div className="form-group">
              <label htmlFor="name">Name: </label>
              <input
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number: </label>
              <input
                type="text"
                name="phone"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email: </label>
              <input
                type="text"
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="service-type">Residential or Corporate: </label>
              <select
                name="service-type"
                id="service-type"
                onChange={(e) => handleSelect(e)}
              >
                <option defaultValue disabled>
                  Residential or Corporate
                </option>
                <option value="residential">Residential</option>
                <option value="commerical">Commercial</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="sqft">Square Footage: </label>
              <input
                type="text"
                name="sqft"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="location">City, State: </label>
              <input
                type="text"
                name="location"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <input type="submit" value="Submit" className="btn" />
        </form>
        <div className="error">
          <h3></h3>
        </div>
      </div>
      <img src={contact} alt="Contact OCD Cleaning Services" />
      <div className="left box">
        <h2>CONNECT WITH US</h2>
        <div className="contact-detail">
          <p>Email Us</p>
          <a href="mailto:ocdcleaning985@gmail.com">ocdcleaning985@gmail.com</a>
        </div>
        <div className="contact-detail">
          <p>Call Us</p>
          <a href="tel:9852767990">985-276-7990</a>
        </div>
        <h2>SOCIAL MEDIA</h2>
        <div className="social-links">
          <i className="fab fa-facebook-square fb"></i>
          <i className="fab fa-instagram insta"></i>
        </div>
        <h2>CLEANING IS OUR OBESSESION</h2>
      </div>
    </section>
  );
}

export default ContactPage;
