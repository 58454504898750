import Header from './components/Header/Header';

import './App.css';
import Routes from './config/Routes';

function App() {
  return (
    <main className="app">
      <Header />
      <Routes />
      <h1 style={{ opacity: 0, zIndex: -999999, position: 'absolute', top: 0 }}>
        OCD Cleaning Services
      </h1>
    </main>
  );
}

export default App;
